<template>
  <div id="app">
    <div id="nav">
      <div id="headerLine"></div>
      <Navbar />
      <div id="banner">
        <transition name="fadeIn">
          <div id="nameBanner" v-if="show" @click="rollStart()">
            <div>DONALD ALBERTI</div>
            <div id="subTitle">
              (1950-) Reductive Abstraction and Color Painting
            </div>
          </div>
        </transition>
      </div>
    </div>
    <router-view :key="$route.fullPath" v-bind="myProps()" />
  </div>
</template>

<script>
import { db } from "@/main.js";
import Navbar from "@/components/navBar.vue/";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: "APP",
  components: {
    Navbar,
  },
  created() {
    this.rollStart();
  },
  async mounted() {
    const querySnapshot = await getDocs(collection(db, "DWAwork"));

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      const artworks = {
        idd: doc.id,
        id: doc.data().id,
        year: doc.data().year,
        dimension: doc.data().dimension,
        medium: doc.data().medium,
        support: doc.data().support,
        series: doc.data().series,
        gp: doc.data().gp,
        imgname: doc.data().imgname,
        red: doc.data().red,
        yellow: doc.data().yellow,
        blue: doc.data().blue,
        order: doc.data().order,
        title: doc.data().title,
      };
      this.inventory.push(artworks);
    });
    console.log("hello data");
    this.timer = setInterval(() => {
      this.rollStart();
    }, 10000);
  },
  data() {
    return {
      inventory: [],
      show: true,
      arr: null,
      supportC: [],
      questions: [],
      inv: [],
      timer: null,
    };
  },
  computed: {
    listOfSupports() {
      let g = Array.from(new Set(this.inventory.map((d) => d.support)));
      return g.sort((a, b) => a.localeCompare(b));
    },
    listOfMediums() {
      let g = Array.from(new Set(this.inventory.map((d) => d.medium)));
      return g.sort((a, b) => a.localeCompare(b));
    },
    listOfSeries() {
      let g = Array.from(new Set(this.inventory.map((d) => d.series)));
      return g.sort((a, b) => a.localeCompare(b));
    },
    listOfReds() {
      return Array.from(new Set(this.inventory.map((d) => d.red)));
    },
    listOfBlues() {
      return Array.from(new Set(this.inventory.map((d) => d.blue)));
    },
    listOfYellows() {
      return Array.from(new Set(this.inventory.map((d) => d.yellow)));
    },
    listOfBlue() {
      if (!this.inventory) {
        return null;
      }
      let m = this.inventory.filter(
        (d) => d.imgname !== "99" && d.imgname !== "98" && d.blue == 1
      );
      return m.sort((a, b) => (a.series > b.series ? 1 : -1));
    },
    listOfRed() {
      if (!this.inventory) {
        return null;
      }
      let m = this.inventory.filter(
        (d) => d.imgname !== "99" && d.imgname !== "98" && d.red == 1
      );
      return m.sort((a, b) => (a.series > b.series ? 1 : -1));
    },
    listOfYellow() {
      if (!this.inventory) {
        return null;
      }
      let m = this.inventory.filter(
        (d) => d.imgname !== "99" && d.imgname !== "98" && d.yellow == 1
      );
      return m.sort((a, b) => (a.series > b.series ? 1 : -1));
    },
    filteredFinal2() {
      let m = this.inventory.filter(
        (d) => d.imgname !== "99" && d.imgname !== "98"
      );
      return m.sort((a, b) => (a.year > b.year ? 1 : -1));
    },
    initialMaquetteSelection() {
      if (!this.inventory) {
        return null;
      }
      let m = this.inventory.filter(
        (d) =>
          d.imgname !== "99" &&
          d.imgname !== "98" &&
          d.imgname !== "95" &&
          d.imgname !== "93" &&
          d.imgname !== "94" &&
          d.imgname !== "92" &&
          d.id !== "DA715" &&
          d.id !== "DA713" &&
          d.id !== "DA711" &&
          d.id !== "DA712" &&
          d.id !== "DA714" &&
          d.series.includes("Maquettes")
      );
      return m.sort((a, b) => (a.series > b.series ? 1 : -1));
    },
    filteredFinall() {
      let m = this.inventory.filter(
        (d) =>
          (d.imgname !== "99" &&
            d.imgname !== "98" &&
            d.support.includes("canvas")) ||
          d.support.includes("marine board") ||
          d.support.includes("canvas + linen") ||
          d.support.includes("honeycomb fibreglass") ||
          d.support.includes("fibreglass") ||
          d.support.includes("aluminum")
      );
      // return m.sort((a, b) => (a.year > b.year ? 1 : -1));
      // return m.sort((a, b) => (a.id > b.id ? 1 : -1));
      function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
            map.set(key, [item]);
          } else {
            collection.push(item);
          }
        });

        return map;
      }
      var grouped = groupBy(m, (d) => d.series);

      let array = Array.from(grouped, ([name, value]) => ({
        name,
        value,
      }));

      return array;
    },
    filteredFinall2() {
      let m = this.inventory.filter(
        (d) =>
          d.imgname !== "99" &&
          d.imgname !== "98" &&
          d.support.includes("paper")
      );
      // return m.sort((a, b) => (a.year > b.year ? 1 : -1));
      // return m.sort((a, b) => (a.id > b.id ? 1 : -1));
      function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
            map.set(key, [item]);
          } else {
            collection.push(item);
          }
        });

        return map;
      }
      var grouped = groupBy(m, (d) => d.series);

      let array = Array.from(grouped, ([name, value]) => ({
        name,
        value,
      }));

      return array;
    },
    filteredFinalll() {
      let m = this.inventory.filter(
        (d) =>
          (d.imgname !== "99" &&
            d.imgname !== "98" &&
            d.support.includes("canvas")) ||
          d.support.includes("marine board") ||
          d.support.includes("canvas + linen") ||
          d.support.includes("honeycomb fibreglass") ||
          d.support.includes("fibreglass") ||
          d.support.includes("aluminum")
      );
      return m.sort((a, b) => (a.year > b.year ? 1 : -1));
    },
    filteredFinalll3() {
      let m = this.inventory.filter(
        (d) =>
          d.imgname !== "99" &&
          d.imgname !== "98" &&
          d.support.includes("paper")
      );
      return m;
    },
  },

  methods: {
    myProps() {
      // console.log("Route Swap");
      if (this.$route.name === "Home") {
        return {
          arr: this.arr,
          initialMaquetteSelection: this.initialMaquetteSelection,
        };
      }
      if (this.$route.name === "Catalogue") {
        return {
          inventory: this.filteredFinal2,
          inventory1: this.filteredFinal2,
          listOfSupports: this.listOfSupports,
          supportC: this.listOfSupports,
          listOfMediums: this.listOfMediums,
          listOfSeries: this.listOfSeries,
          listOfReds: this.listOfReds,
          listOfBlues: this.listOfBlues,
          listOfYellows: this.listOfYellows,
        };
      }
      if (this.$route.name === "Exhibitions") {
        return {
          listOfRed: this.listOfRed,
          listOfBlue: this.listOfBlue,
          listOfYellow: this.listOfYellow,
        };
      }
      if (this.$route.name === "Works") {
        return {
          inventory: this.filteredFinal2,
          listOfSupports: this.listOfSupports,
          listOfMediums: this.listOfMediums,
          listOfSeries: this.listOfSeries,
          arr: this.arr,
          listOfRed: this.listOfReds,
          filteredFinall: this.filteredFinall,
          popInv: this.inv,
          filteredFinalll: this.filteredFinalll,
          filteredFinal: this.filteredFinal,
        };
      }
      if (this.$route.name === "Biography") {
        return {
          inventory: this.filteredFinal2,
          listOfSupports: this.listOfSupports,
          listOfMediums: this.listOfMediums,
          listOfSeries: this.listOfSeries,
        };
      }
      if (this.$route.name === ":Selection") {
        return {
          inventory: this.filteredFinal2,
        };
      }

      return [];
    },
    rollStart() {
      this.arr = [];
      while (this.arr.length < 3) {
        var r = Math.floor(Math.random() * 9) + 1;
        if (this.arr.indexOf(r) === -1) this.arr.push(r);
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
<style>
/* Outer Container to hold views */
.Container1 {
  padding-left: 5%;
  padding-right: 5%;
}

#app {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  width: 100vw;
  /* height: 100vh; */
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

#nav {
  text-align: center;
  padding-bottom: 48px;
  background-color: white;
  width: 100vw;
  min-width: 280px;
  padding-left: 0px;
  padding-right: 0px;
  position: fixed;
  z-index: 1;
}

#nav a {
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  font-size: 1em;
  transition: all 500ms;
}
#nav a:hover {
  color: #eeaf11;
}

#nav a.router-link-exact-active {
  color: #eeaf11;
  font-size: 1em;
}

#banner {
  color: rgb(0, 0, 0);
  font-size: 2.5rem;
  text-align: center;
  font-weight: bold;
  padding-top: 80px;
  min-width: 200px;
  z-index: -1;
}
#subTitle {
  font-size: 0.7em;
}
#navbar {
  padding: 5px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -0%);
  width: 385px;
  background-color: white;
}

#nameBanner {
  position: fixed;
  left: calc(50vw - 200px);
  min-width: 390px;
}
@media only screen and (min-width: 1400px) {
  #nameBanner {
    animation: left-to-right 4s ease-in forwards 1 alternate;
    background-color: white;
    font-size: 1.4vw;
    z-index: 5000;
  }
}
@media only screen and (max-width: 1399px) {
  #nameBanner {
    background-color: white;
    font-size: 0.5em;

    z-index: 1;
  }
}
@media only screen and (max-width: 429px) {
  #nameBanner {
    background-color: white;
    font-size: 4vw;
  }
  /* .Container1 {
    padding-left: 0%;
    padding-right: 0%;
  } */
}

#headerLine {
  position: fixed;
  padding-top: 57px;
  left: 5vw;
  height: 0px;
  width: 1px;
  border-bottom: 1px solid #000;
  -webkit-animation: increase 3.5s;
  -moz-animation: increase 3.5s;
  -o-animation: increase 3.5s;
  animation: increase 3.5s;
  animation-fill-mode: forwards;
  animation-delay: 3.5s;
  z-index: 2;
}

/* keyframes */
@keyframes left-to-right {
  0% {
    transform: translateX(0%);
  }
  70% {
    transform: translateX(calc(-50vw + 55%));
  }
  75% {
    transform: translateX(calc(-50vw + 55%));
  }
  90% {
    transform: translateX(calc(-50vw + 55%)) translateY(-100%);
  }
  100% {
    transform: translateX(calc(-50vw + 55%)) translateY(-100%);
  }
}
@keyframes down-and-out {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  70% {
    transform: translateX(calc(-10vw + 60%));
  }
  75% {
    transform: translateX(calc(-10vw + 60%));
    opacity: 0;
  }
  85% {
    opacity: 0.2;
  }
  90% {
    transform: translateX(calc(-10vw + 60%)) translateY(100%);
  }
  100% {
    transform: translateX(calc(-10vw + 60%)) translateY(100%);
  }
}

@keyframes increase {
  100% {
    width: 90vw;
  }
}
@keyframes increaseLess {
  100% {
    width: 10vw;
  }
}
</style>
