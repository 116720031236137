import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
    meta: {
      next: "Works",
    },
  },
  {
    path: "/Catalogue",
    name: "Catalogue",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Catalogue.vue"),
    props: true,
    meta: {
      next: "Contact",
      prev: "Biography",
    },
  },
  {
    path: "/Contact",
    name: "Contact",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
    meta: {
      prev: "Catalogue",
    },
  },
  {
    path: "/About/Exhibitions",
    name: "Exhibitions",
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About/Exhibitions.vue"),
  },
  {
    path: '/about/Biography',
    name: 'Biography',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About/Biography.vue')
  },
  {
    path: "/Exhibitions/Works",
    name: "Works",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Exhibitions/Works__.vue"
      ),
    meta: {
      next: "Biography",
      prev: "Home",
    },
  },
  {
    path: "/Exhibitions/Works/:Selection",
    name: ":Selection",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/SubWorks/WorkTemplate.vue"
      ),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router
