import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import LazyLoadDirective from './directives/LazyLoadDirective'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAydlnvV_mND-EbivDvSpDh8SaZi8bmC_0",
  authDomain: "donaldalbertiweb.firebaseapp.com",
  projectId: "donaldalbertiweb",
  storageBucket: "donaldalbertiweb.appspot.com",
  messagingSenderId: "421206639121",
  appId: "1:421206639121:web:aeeee299d880eae7e467b0",
  measurementId: "G-H28WJPDBCR"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
// app.use(LazyLoadDirective);
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LdekOEiAAAAAB3GoQR1haz2wEpH4lgdL4_IwKaw"),
  
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: false,
  });
// app.directive('lazyload', LazyLoadDirective);
export {
    db,
    appCheck
}
createApp(App).use(router).use(LazyLoadDirective).mount('#app')


