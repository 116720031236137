<template>
  <div class="Container1" id="home">
    <div class="home2">
      <div
        id="innercontainer"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :class="{ active: hover }"
      >
        <div id="heroIMG">
          <ImageItem
            v-if="this.initialMaquetteSelection.length"
            styles="parent-style"
            class="image__item"
            :key="this.initialMaquetteSelection[this.arr[0]].id"
            :source="
              require('@/assets/png-Edits/' +
                this.initialMaquetteSelection[this.arr[0]].id +
                '-edit' +
                '.png')
            "
            alt="random image"
          />
        </div>

        <div id="heroIMG">
          <ImageItem
            v-if="this.initialMaquetteSelection.length"
            styles="parent-style"
            class="image__item"
            :key="this.initialMaquetteSelection[this.arr[1]].id"
            :source="
              require('@/assets/png-Edits/' +
                this.initialMaquetteSelection[this.arr[1]].id +
                '-edit' +
                '.png')
            "
            alt="random image"
          />
        </div>
        <div id="heroIMG">
          <ImageItem
            v-if="this.initialMaquetteSelection.length"
            styles="parent-style"
            class="image__item"
            :key="this.initialMaquetteSelection[this.arr[2]].id"
            :source="
              require('@/assets/png-Edits/' +
                this.initialMaquetteSelection[this.arr[2]].id +
                '-edit' +
                '.png')
            "
            alt="random image"
          />
        </div>

      </div>
    </div>
    <div
      :style="{
        position: 'fixed',
        'z-index': 3,
        top: '70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }"
    >
      <!-- Donald Alberti Website -->
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// import Footer from "@/components/Footer.vue";
import image from "../assets/full-size/DA163.jpg";
import ImageItem from "../components/ImageItem.vue";

export default {
  components: { ImageItem, },
  name: "Home-e",
  data() {
    return {
      image,
      hover: false,
    };
  },
  props: {
    arr: Array,
    initialMaquetteSelection: Array,
  },

  methods: {
    hoverShowDetails() {
      this.hover = !this.hover;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.home2 {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;

  /* max-height: calc(100vh - 180px); */
}

.home2::before {
  -webkit-animation: myfirst 7s forwards;
  animation: myfirst 7s forwards;
  content: "";
  background-image: url("~@/assets/full-size-original/intro.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  background-position: center;
  top: 130px;
  right: 25px;
  bottom: 57px;
  left: 5px;
  opacity: 0.55;
  overflow: hidden;
}

#tooltip2 {
  color: rgb(0, 0, 0);
}
#tooltip3 {
  color: rgb(0, 0, 0);
}
/* #banner {
  color: rgb(0, 0, 0);
  font-size: 2rem;
  text-align: center;
  padding: 50px;
  padding-top: 100px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -0%);
} */
#bio {
  font-size: 1rem;
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  color: rgb(0, 0, 0);
}
#container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding-top: 270px;
  padding-left: 5%;
  padding-right: 5%;
  width: 90vw;
  justify-content: center;
}

#innercontainer {
  /* padding-top: 35vh; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* min-height: 470px; */
  min-height: 400px;
  /* opacity: 0.9; */
  column-gap: 0px;
  align-content: space-around;

  /* border: 1px solid black; */
}
#heroIMG > figure:hover {
  /* border-top: 2px solid rgb(219, 218, 218);
  border-bottom: 2px solid rgb(219, 218, 218); */
  /* border-left: 2px solid rgb(219, 218, 218); */
  /* border-right: 20px solid rgb(219, 218, 218); */
}

#heroIMG {
  width: 300px;

  position: relative;
}

/* new sht */
#heroIMG > .image__wrapper.image__item {
  opacity: 1;
}
#heroIMG > .image__wrapper.image__item.loaded {
  opacity: 1;
  transition: all 3s ease-in-out;
}

#heroIMG > figure {
  margin: 0;
}
#heroIMG > .image__item {
  min-width: 15vw;
  max-width: 290px;

  justify-content: space-around;
}

#tooltip {
  position: absolute;
  text-align: center;

  text-align: left;

  top: 55%;
  left: 50%;
  z-index: 5;
  opacity: 1;
}
#tooltip2 {
  font-size: 0.8rem;
}
/* media queries */
@media only screen and (max-width: 1024px) {
  #heroIMG > .image__wrapper {
    width: 100vw;
    min-height: 400px;
    justify-content: space-around;
    margin-bottom: 200px;
  }
  #heroIMG {
    width: 100vw;
    display: flex;
    justify-content: space-around;
    padding-bottom: 100px;
  }
  #innercontainer {
    column-gap: 260px;
    padding-top: 35vh;
    /* border: 1px solid black; */
  }
  .home2::before {
    -webkit-animation: myfirst 7s forwards;
    animation: myfirst 7s forwards;
    content: "";
    background-image: url("~@/assets/full-size-original/intro.jpg");
    background-size: auto 450px;
    background-repeat: no-repeat;
    position: fixed;
    width: calc(100vw - 10px);
    background-attachment: initial;
    opacity: 0.55;
    z-index: -10;
  }
}
/* .food-enter,
.food-leave-to {
  opacity: 1;

  transition: all 3s ease-in-out;
}

.food-leave-active {
  opacity: 0;
} */
/* keyframe Animations */
@-webkit-keyframes myfirst {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}

@keyframes myfirst {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}
/* transitions */
.bounce-enter-active {
  animation: myfirst 0.5s linear;
}
.bounce-leave-active {
  animation: myfirst 0.5s linear;
}
</style>
