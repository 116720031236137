<template>
  <figure v-lazyload class="image__wrapper">
    <img :class="styles" :data-url="source" @error="setAltImg" alt="-" />
  </figure>
</template>
<script>
export default {
  name: "ImageItem",

  props: {
    source: {
      type: String,
      required: true,
    },
    styles: {
      type: String,
      required: true,
    },
  },
  methods: {
    // setAltImg() {
    //   this.source = require("@/assets/full-size/" + "DA999" + ".jpg");
    // },
  },
};
</script>
<style scoped>
.parent-style {
  min-width: 15vw;
  max-width: 290px;
  animation: myfirst 5s forwards;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
}
@-webkit-keyframes myfirst {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes myfirst {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.parent-style2 {
  width: 240px;
  padding: 5px;
}
.parent-style3 {
  min-width: 2vw;
  max-width: 40px;
}
.parent-styleGALLA {
  min-width: 70px;
  max-width: 8vw;
  max-height: 250px;
}
.parent-style4 {
  min-width: 25vw;
  max-width: 25%;
  margin: 0;
}
.parent-style-SubWorks {
  /* height: 50vh; */
  /* min-height: 50vh; */
  /* max-height: 50vh; */
  /* min-width: 300px; */

  max-width: 100%;
  max-height: 600px;
  /* max-height: 600px; */
  /* max-height: 500px; */
}
@media only screen and (max-width: 910px) {
  .parent-style-SubWorks {
    /* height: 50vh; */
    /* min-height: 50vh; */
    /* max-height: 50vh; */
    /* min-width: 300px; */

    max-width: 100%;
    max-height: 500px;
  }
}

.parent-style6 {
  max-height: 10vh;
  max-width: 100px;
}
figure {
  margin-left: 5px;
  margin-right: 5px;
}
.parent-style7 {
  min-width: 200px;
  max-width: 100%;
  max-height: 60vh;
}
.parent-style8 {
  min-width: 50%;
  max-width: 60%;
  flex-basis: 1;
  height: auto;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
}
.parent-style-works {
  min-width: 50%;
  max-width: 30%;

  height: auto;
  -webkit-filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
}
.parent-style9 {
  max-width: 100%;

  /* display: flex; */
}
</style>
