<template>
  <nav id="NavContent">
    <div class="menu-item"><router-link to="/">Home</router-link></div>

    <!-- <router-link to="/Exhibitions">Exhibition</router-link> | -->
    <div class="menu-item">
      <router-link to="/Exhibitions/Works">Works</router-link>
    </div>

    <!-- <Works title="Works" /> -->
    <!-- <Aboutmenu title="Biography" /> -->
    <div class="menu-item">
      <router-link to="/About/Biography">Biography</router-link>
    </div>
    <div class="menu-item">
      <router-link to="/Catalogue">Catalogue</router-link>
    </div>
    <div class="menu-item">
      <router-link to="/Contact">Contact</router-link>
    </div>
  </nav>
</template>

<script>
// import Works from "../components/Works";
// import Aboutmenu from "./Aboutmenu.vue";
export default {
  name: "navbar-r",
  components: {
    // Works,
    // Aboutmenu,
  },
};
</script>
<style>
nav {
  position: fixed;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
  top: 30px;
  z-index: 15;
}
#nav {
  background-color: rgb(255, 255, 255);
}
nav .menu-item {
  color: #fff;
  padding: 15px 5px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  display: flex;
  transition: 0.4s;
  background-color: white;
  font-size: 0.9em;
}
nav .menu-item.active,
nav .menu-item:hover {
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
</style>
